<template>
  <div v-if="partner && partner.asset">
    <img :src="getImgUrl(partner.asset)" :width="`${width || '18'}`" alt="partner icon"/>
  </div>
</template>

<script setup>
const glob = import.meta.glob('@/assets/img/*-icon.png', { eager: true })
const images = Object.fromEntries(
  Object.entries(glob).map(([key, value]) => [key, value.default])
)
const getImgUrl = (asset) => {
  return images['/assets/img/' + asset]
}
</script>
<script>
import facebookPartnerImage from '@/assets/img/meta-icon.png'
export default {
  name: 'basicPartnerIcon',
  props: {
    value: [Number, String],
    width: {
      type: [Number, String],
      default: '18'
    }
  },
  data () {
    return {
      facebookPartnerImage
    }
  },
  computed: {
    partner () {
      let partner
      if (typeof this.value === 'number') {
        partner = this.$store.getters.partners.filter(partner => partner.partnerId === this.value)[0]
      } else if (typeof this.value === 'string') {
        partner = this.$store.getters.partners.filter(partner => partner.name === this.value)[0]
      }
      return partner
    }
  }
}
</script>

<style scoped>

</style>
